#menu .container {
    display: grid;
    grid-template-columns: 40% 1fr;
    padding: 1rem 0;
}

#menu .container .left {
    display: grid;
    grid-template-columns: 7% 1fr;
    align-items: center;
    gap: .6rem;
}

#menu .container .left .text {
    color: black;
    font-weight: bold;
    font-size: 20px;
}


/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}