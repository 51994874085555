#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    justify-content: center;
}

#header .header1 .top {
    width: 12%;
    margin-left: 7.5vw;
    padding-top: .2rem;
}

#header .header1 .container .title {
    color: white;
    max-width: 720px;
    margin-top: -9rem;
}

#header .header1 .container .text {
    color: white;
    max-width: 520px;
}

#header .mobile {
    display: none;
}

#header .header2 {
    background-color: #F9F9F9;
}

#header .header2 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

#header .header2 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .left .text {
    width: 75%;
}

#header .header2 .container .left #ctaWpp {
    width: 40%;
}

#header .header2 .container .carousel {
    width: 650px;
}

#header .header3 .container .title {
    text-align: center;
}

#header .header3 .container .text {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
}

#header .header3 .container .text:first-of-type {
    font-weight: bold;
}

#header .header3 .container .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 0 auto;
}

#header .header3 .container #ctaWpp {
    margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 90vh;
    }

    #header .header1 .top {
        margin-left: 40vw;
        padding-top: 0;
    }

    #header .header1 .container .title {
        max-width: 100%;
    }

    #header .header1 .container .text {
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
    }

    #header .mobile {
        display: block;
    }

    #header .mobile .container {
        display: flex;
        padding: 1rem 0;
        align-items: center;
        justify-content: center;
    }

    #header .mobile .container .left {
        display: grid;
        grid-template-columns: 10% 1fr;
        align-items: center;
        gap: .6rem;
    }

    #header .mobile .container .left .text {
        font-size: 18px;
    }

    #header .mobile .container #CtaTelWpp {
        display: none;
    }

    #header .header2 .container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #header .header2 .container .left .text {
        width: 100%;
        text-align: center;
    }

    #header .header2 .container .left #ctaWpp {
        width: 40%;
        margin: 0 auto;
    }

    #header .header2 .container .carousel {
        width: 600px;
        margin: 0 auto;
    }

    #header .header3 .container {
        padding-bottom: 17rem;
    }

    #header .header3 .container .gallery {
        grid-template-columns: 1fr;
    }

    #header .header3 .container #ctaWpp {
        width: 40%;
        margin: 0 auto;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 70vh;
    }

    #header .header1 .top {
        margin-left: 40vw;
    }

    #header .header1 .top {
        width: 20%;
    }

    #header .header1 .top img {
        width: 100%;
    }

    #header .header1 .container #ctaWpp {
        width: 70%;
    }

    #header .mobile .container .left .text {
        font-size: 16px;
    }

    #header .header2 .container .left #ctaWpp {
        width: 70%;
    }

    #header .header2 .container .carousel {
        width: 380px;
    }

    #header .header3 .container {
        padding-bottom: 11rem;
    }

    #header .header3 .container #ctaWpp {
        width: 70%;
    }
}