#ctaTel {
    background-color: #E7292B;
    font-weight: 700;
    padding: 1.2rem 0rem;
    width: 30%;
    height: fit-content;
    border-radius: 7px;
}

#ctaTel a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaTel a figure {
    width: 8%;
}

#ctaTel a figure img {
    width: 100%;
    display: block;
}

#ctaTel p {
    font-size: 18px;
    font-weight: 700;
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaTel {
        width: 60%;
    }

    #ctaTel p {
        font-size: 17px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaTel {
        width: 75%;
    }

    #ctaTel p {
        font-size: 17px;
    }
}