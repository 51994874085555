#about .about1 {
  background-color: #E43232;
}

#about .about1 .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
}

#about .about1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

#about .about1 .container .left .title {
  color: white;
  max-width: 700px;
}

#about .about1 .container .left .text {
  color: white;
  font-weight: bold;
  max-width: 540px;
}

#about .about1 .container .left #ctaWpp {
  width: 40%;
}

#about .about1 .container .right img {
  width: 60%;
}

#about .about2 {
  background-color: #FEDF30;
}

#about .about2 .container {
  padding: .1rem 0;
  display: flex;
  justify-content: center;
}

#about .about2 .container .content {
  display: grid;
  grid-template-columns: 7% 1fr;
  gap: .5rem;
  align-items: center;
}

#about .about2 .container .text {
  color: black;
  font-weight: bold;
  font-size: 24px;
}

#about .about3 .container .title {
  text-align: center;
}

#about .about3 .container .text {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}

#about #CtaTelWpp {
  justify-content: center;
}

#about #CtaTelWpp #ctaWpp a figure,
#about #CtaTelWpp #ctaTel a figure {
  width: 6%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    grid-template-columns: 1fr;
  }

  #about .about1 .container .left {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  #about .about1 .container .left .title {
    max-width: 100%;
    text-align: center;
  }

  #about .about1 .container .left .text {
    max-width: 100%;
    text-align: center;
  }

  #about .about1 .container .left #ctaWpp {
    margin: 0 auto;
  }

  #about .about1 .container .right {
    order: -1;
    margin-top: -15rem;
  }

  #about .about1 .container .right img {
    width: 40%;
  }

  #about .about2 {
    background-color: #FEDF30;
  }

  #about .about2 .container {
    padding: 1rem 0;
  }

  #about .about2 .container .content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  #about .about2 .container .content figure img {
    width: 15%;
  }

  #about .about2 .container .text {
    text-align: center;
    font-size: 18px;
  }

  #about .about3 .container .text {
    max-width: 100%;
  }

  #about #CtaTelWpp {
    justify-content: center;
  }

  #about #CtaTelWpp #ctaWpp,
  #about #CtaTelWpp #ctaTel {
    width: 40%;
  }

  #about #CtaTelWpp #ctaWpp a figure,
  #about #CtaTelWpp #ctaTel a figure {
    width: 7%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .right {
    margin-top: -10rem;
  }

  #about .about1 .container .right img {
    width: 50%;
  }

  #about .about1 .container .left #ctaWpp {
    width: 70%;
  }

  #about .about2 .container .text {
    font-size: 12px;
  }

  #about #CtaTelWpp #ctaWpp,
  #about #CtaTelWpp #ctaTel {
    width: 70%;
  }

  #about #CtaTelWpp #ctaWpp a figure,
  #about #CtaTelWpp #ctaTel a figure {
    width: 6%;
  }
}