#ctaWpp {
    background-color: #15BE63;
    font-weight: 700;
    padding: 1.2rem 0;
    width: 20%;
    height: fit-content;
    border-radius: 7px;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaWpp a figure {
    width: 6%;
}

#ctaWpp a figure img {
    width: 100%;
    display: block;
}

#ctaWpp p {
    font-size: 18px;
    font-weight: 700;
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 60%;
    }

    #ctaWpp p {
        font-size: 17px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp {
        width: 75%;
    }

    #ctaWpp p {
        font-size: 17px;
    }
}